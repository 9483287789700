import React from 'react';

export const HamburgerButton = ({
                                 open,
                                 className,
                                 color,
                                 transitionTime,
                                 easing}) => {
    const tt = transitionTime || 350,
        e = easing || 'ease-out';

    const renderPath = (d1, d2) => (
        <path
            style={{
                transition: `d ${tt}ms ${e}`
            }}
            d={open ? d2 : d1}
            fill={'black'}
            className={'mainShape'}
        />
    )

    return (
        <svg
            className={`hamburgerButton ${className || ''}`}
            viewBox="0 0 132.29166 132.29167"
            height="30" width="30"
        >
            {renderPath(
                "m 4.0263095,14.267981 125.3180305,-0.01822 0.003,18.591625 -125.3180367,0.01823 z",
                "M 28.846694,14.020461 117.45992,106.30873 104.83715,119.455 16.223931,27.166726 Z"
            )}
            {renderPath(
                "M 3.4245129,57.752314 H 128.74254 V 76.343939 H 3.4245129 Z",
                "M 56.27702,57.701561 H 77.059562 V 76.386636 H 56.27702 Z"
            )}
            {renderPath(
                "m 3.3906325,100.69908 125.3180175,0.008 -0.001,18.59162 -125.3180167,-0.008 z",
                "M 16.175122,106.15278 104.78835,13.864516 117.41112,27.010786 28.79789,119.29905 Z"
            )}
        </svg>
    );
}