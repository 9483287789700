import React, {useState} from 'react';
import './App.css';
import useEffectOnce from "./utils/UseEffectOnce";
import {getLoginRedirectResult, login} from "./Firebase/firebase";
import {load, remove, save} from "./utils/utils";
import {ClipBoard} from "./Clipboard/ClipBoard";
import {Loader} from "./Loader/Loader";
import {Menu} from "./Menu/Menu";

function App() {
    const [loggingIn, setLoggingIn] = useState(true);
    const [user, setUser] = useState(load('user'));

    useEffectOnce(() => {
        getLoginRedirectResult().then(
            usr => {
                setLoggingIn(false);
                if (!usr) return;
                save('user', usr);
                setUser(usr);
                window.sessionStorage.removeItem('pending')
            },
            () => {
                setLoggingIn(false);
            }
        ).catch(e => {
            console.log('e: ', e);
        })
    })

    const doLogin = () => {
        if (user)
            setUser(undefined)
        else {
            window.sessionStorage.setItem('pending', 1)
            login()
        }
    }

    const content = loggingIn ? (
        <>
            <Loader/>
            <button
                className="button basic primary"
                onClick={() => {
                    window.sessionStorage.removeItem('pending');
                    setLoggingIn(false)
                }}
            >
                Avbryt
            </button>
        </>
    ) : !!user ? (
        <>
            <Menu>
                <button
                    className={'basic button secondary'}
                    onClick={() => {
                        setUser(undefined)
                        remove('user');
                        remove('person');
                        remove('projects');
                    }}
                >
                    Log out
                </button>
            </Menu>
            <ClipBoard
                user={user}
            />
        </>

    ) : (
        <>
            <br/>
            <br/>
            <h1>
                Log in to start clipping
            </h1>
            <button
                onClick={doLogin}
                className={'button basic primary'}
            >
                Log in
            </button>
        </>
    )

    return (
        <div className="App">
            {content}
        </div>
    );
}

export default App;
