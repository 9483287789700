// import firebase from 'firebase';
import {initializeApp} from 'firebase/app';
// import {getDatabase, ref, set, ref, onValue, child} from 'firebase/database';
import {getDatabase, ref, set, get, child, onValue} from 'firebase/database';

import { getAuth, signInWithRedirect, getRedirectResult, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAVfqZJ4x4z_WdiHz9H4BPLatjSTSLmyfI",
    authDomain: "chaos-bag.firebaseapp.com",
    databaseURL: "https://chaos-bag.firebaseio.com",
    projectId: "chaos-bag",
    storageBucket: "chaos-bag.appspot.com",
    messagingSenderId: "1056151336974",
    appId: "1:1056151336974:web:0bca6e97685fca30ab245f"
};

initializeApp(firebaseConfig);


const db = getDatabase();
// const auth = getAuth();

export const listen = (keys, callBack) =>
        onValue(ref(db, keys.join('/')), (snap) => {
            callBack(snap.val())
        });

export const firebaseSet = (keys, value) =>
    set(ref(db, keys.join('/')), value);

export const firebaseGet = (keys, callBack) =>
        get(child(ref(getDatabase()), keys.join('/'))).then((snapshot) => {
            if (snapshot.exists())
                callBack(snapshot.val());
        }).catch((error) => {
            console.error(error);
        });

//
// export const firebaseAdd = (key, value) => {
//     const push = dbRef.child(key)
//         .push();
//     push.set(push.key, value);
// }

// LOGIN

// const provider = new auth.GoogleAuthProvider();



export const login = () =>
    signInWithRedirect(getAuth(), new GoogleAuthProvider())

export const getLoginRedirectResult = () => {
    return new Promise((resolve, reject) => {
        getRedirectResult(getAuth())
                .then((result) => {
                    if (result && result.user)
                        resolve(result.user)
                    else
                        reject()
                    },
                    (error) => {
                        reject(error);
                    })


        }
    )
}

