import React, { useState} from 'react';
import useEffectOnce from "../utils/UseEffectOnce";
import {firebaseGet, firebaseSet, listen} from "../Firebase/firebase";
import {Loader} from "../Loader/Loader";
import {copyToClipboard, isValidHttpUrl} from "../utils/utils";
import {ReactComponent as LinkIcon} from "./xLink.svg";
import {ReactComponent as DeleteIcon} from "./delete.svg";
import './ClipBoard.css';
import classnames from "classnames";

let deleteTimeout;

export const ClipBoard = ({user}) => {
    const [clips, setClips] = useState(undefined)
    const [text, setText] = useState('')
    const [copied, setCopied] = useState(-1)
    const [deleting, setDeleting] = useState(-1)


    useEffectOnce(() => {
        firebaseGet(['clips', user.uid], setClips)
        listen(['clips', user.uid], val => {
            setClips(val)
        })
    })

    const wrap = (children) => (
        <div className="clipBoard flexCenter">
            <div className="clipScroller">
            <div className="clipClop">
                {children}
            </div>
            </div>
        </div>
    )

    if (!clips) return wrap(<Loader/>);

    const submit = () => {
        if (!text) return;
        firebaseSet(['clips', user.uid], [...clips, text])
        setText('')
    }

    const deleteClipAt = index => {
        const newClips = [...clips];
        newClips.splice(index,1)
        firebaseSet(['clips', user.uid], newClips)
        setDeleting(-1)

        if (deleteTimeout) {
            clearTimeout(deleteTimeout)
            deleteTimeout = null
        }
    }

    const renderClip = (c, i) => {
        const link = isValidHttpUrl(c)
        const deletingThis = deleting === i;
        return (
            <div
                key={i}
                className={classnames('clipWrapper', {
                    link,
                    copied: copied === i
                })}
            >
                <DeleteIcon
                    className={classnames({danger: deletingThis})}
                    onClick={() => {
                        if (deletingThis)
                            deleteClipAt(i)
                        else {
                            if (deleteTimeout)
                                clearTimeout(deleteTimeout)
                            setDeleting(i)
                            deleteTimeout = setTimeout(() => {
                                setDeleting(-1)
                                deleteTimeout = null;
                            }, 1000)
                        }
                    }}
                />
                <div
                    className="clip primary"
                    onClick={() => {
                        setCopied(i)
                        copyToClipboard(c)
                        setTimeout(() => setCopied(-1), 1)
                    }}
                >
                    {c}
                </div>
                {link && (
                    <a
                        href={c}
                        target={'_blank'}
                        rel={'noreferrer'}
                    >
                        <LinkIcon/>
                    </a>
                )}
            </div>
        )
    }

    return wrap(
        <>
            <div className="addClip">
                <input
                    type="text"
                    className={'clipInput'}
                    onKeyDown={event => {
                        let code;
                        if (event.key !== undefined) {
                            code = event.key;
                        } else if (event.keyIdentifier !== undefined) {
                            code = event.keyIdentifier;
                        } else if (event.keyCode !== undefined) {
                            code = event.keyCode;
                        }
                        if([13, 'Enter'].includes(code))
                            submit()
                    }}
                    value={text}
                    onChange={e => setText(e.target.value)}
                />
                <button
                    className="button basic slim primary"
                    onClick={() => {
                        if (!text) return;
                        firebaseSet(
                            ['clips', user.uid],
                            [...clips, text]
                        )
                        setText('')
                    }}
                >
                    Add
                </button>
            </div>
            <br/>
            <br/>
            <br/>
            {clips.map(renderClip)}
        </>
    );
}