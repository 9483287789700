import React, {useState} from 'react';
import './Menu.css';
import {HamburgerButton} from "./HamburgerButton";

export const Menu = ({children}) => {
    const [show, setShow] = useState(false);

    return (
        <>
            <div
                className={`menuTouchOutsideSensor ${show ? ' show' : ''}`}
                onClick={() => setShow(false)}
            />
            <button
                className={'menuButton primary'}
                onClick={() => setShow(!show)}
            >
                <HamburgerButton
                    open={show}
                    transitionTime={400}
                />
            </button>
            <div
                className={`menu primary${show ? ' show' : ''}`}
                onClick={(e) =>
                    e.target.tagName === 'A' && setShow(false)
                }
            >
                {children}
            </div>
        </>
    );
}