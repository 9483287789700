export const isValidHttpUrl = (string) => {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export const getDifferenceString = (now, time) => {
    let hours = time.diff(now, 'hours');
    if (hours > 24) {
        const days = time.diff(now, 'days');
        return `${days} dag${days > 1 ? 'er' : ''} igjen.`
    }
    let minutes = time.diff(now, 'minutes') - (hours * 60);
    let seconds = time.diff(now, 'seconds') - ((hours * 3600) + (minutes * 60));

    let hourString = hours ? `${hours}:` : '';
    let minuteString = hours || minutes ? `${hours === 0 ? minutes : doubleDigits(minutes)}:` : '';

    if (hours < 0 && minutes < 0) minutes = -minutes;
    if (minutes < 0 && seconds < 0) seconds = -seconds;
    let singleSeconds = hours === 0 && minutes === 0;


    return `${hourString}${minuteString}${singleSeconds ? seconds : doubleDigits(seconds)}`;
};

const doubleDigits = (value) => {
    let rawString = `${value}`;
    return rawString.length > 1 ? rawString : `0${value}`;
};



export const remove = (key) => {
    localStorage.removeItem(key);
}

export const save = (key, value) => {
    let thing = value;
    try {
        thing = JSON.stringify(value);
    } catch (ignored) {}
    localStorage.setItem(key, thing);
}


export const load = (key) => {
    let thing = localStorage.getItem(key);
    if (!thing) return;
    try {
        thing = JSON.parse(thing);
    } catch (ignored) {}
    return thing;
}

export const mailToKey = mail => mail.replace('.', '_').toLowerCase().trim();


export const generateUuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : r & (0x3 | 0x8);
        return v.toString(16);
    });
};


export const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};
